import React from 'react'
import ArchiveCardWrapper from '../../../components/ArchiveCardWrapper/ArchiveCardWrapper'
import ArchiveWrapper from '../../../components/ArchiveWrapper/ArchiveWrapper'
import SEO from '../../../components/seo'
import { Header1 } from '../../../components/Headers/Headers'
import MonumentCardZlotyUlan from '../../../monuments/kaluszyn/MonumentCardZlotyUlan'
import MonumentCardKaluszynPomnikNiepodleglosci from '../../../monuments/kaluszyn/MonumentCardKaluszynPomnikNiepodleglosci'
import MonumentCardRocznik from '../../../components/MonumentCard/MonumentCardRocznik'
import MonumentCardAsset from '../../../components/MonumentCard/MonumentCardAsset'

const KaluszynArchive = () => {
  return (
    <ArchiveWrapper>
      <SEO title="Materiały dotyczące gminy Kałuszyn" />
      <ArchiveCardWrapper>
        <Header1>Materiały dotyczące gminy Kałuszyn</Header1>
        <MonumentCardZlotyUlan />
        <MonumentCardKaluszynPomnikNiepodleglosci />
        <MonumentCardRocznik
          title={
            'Działania WP z zablokowanych transportów na linii kolejowej Mińsk Mazowiecki - Siedlce'
          }
          author={'Piotr Twardowski'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/KaluszynRoczniktom26PiotrTwardowskiDzialaniaWPzzablokowanychtransportownaliniikolejowejMinskMazowiecki-Siedlce.pdf'
          }
        />
        <MonumentCardRocznik
          title={'Płonący szynk – tragedia w rodzinie Siudek'}
          author={'Ewa Migdalska'}
          linkTo={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/rocznik/KaluszynRoczniktom262018EwaMigdalskaPlonacyszynk%E2%80%93tragediawrodzinieSiudek.pdf'
          }
        />
        <MonumentCardAsset
          title={'K. Sr. Nowy Kościół w Kałuszynie (1896r)'}
          assetName={'AUDIOBOOK'}
          audioIcon
          audioURL={
            'https://edukacjaziemiaminska.s3.eu-west-1.amazonaws.com/audiobooki/K.+Sr.+Nowy+Kos%CC%81cio%CC%81%C5%82+w+Ka%C5%82uszynie+(1896r).mp3'
          }
        />
      </ArchiveCardWrapper>
    </ArchiveWrapper>
  )
}

export default KaluszynArchive
